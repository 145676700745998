import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FormEmployeeSelect, FormSwitch } from 'components/common/hook-form';

import { getIsEditableFile } from 'hooks/common/use-file-upload/types';

import styles from './manage-subscribers-multi-attachment.module.scss';

const ManageSubscribersMultiAttachment = ({ data, index }) => {
  const form = useFormContext();
  const { t } = useTranslation(['FileSubscribers', 'AddAsset']);

  const { mimeType } = data;

  const watchAllowSubscriberSelection = form.watch(
    `subscribersForAttachments.${index}.allowSubscriberSelection`
  );

  return (
    <>
      {getIsEditableFile({ mimeType }) && (
        <div className={styles.root}>
          <FormSwitch
            name={`subscribersForAttachments.${index}.allowSubscriberSelection`}
            label={t('SubscribeEmployeesToggle', { ns: 'AddAsset' })}
          />

          {watchAllowSubscriberSelection && (
            <FormEmployeeSelect
              name={`subscribersForAttachments.${index}.subscribers`}
              label={t('Subscribers')}
              isMulti
              params={{
                isPostMethod: true
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

ManageSubscribersMultiAttachment.propTypes = {
  data: PropTypes.shape({
    mimeType: PropTypes.string.isRequired,
    allowSubscriberSelection: PropTypes.bool,
    fileId: PropTypes.string,
    subscribers: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  index: PropTypes.number.isRequired
};

export default ManageSubscribersMultiAttachment;
