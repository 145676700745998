export default api => ({
  subscribe: ({ workspaceId, entityId, entityType }) =>
    api.post(`v1/${workspaceId}/subscriptions/subscribe/`, {
      entity_id: entityId,
      entity_type: entityType
    }),

  unsubscribe: ({ workspaceId, entityId, entityType }) =>
    api.post(`v1/${workspaceId}/subscriptions/unsubscribe/`, {
      entity_id: entityId,
      entity_type: entityType
    }),

  fetchSubscribers: ({ workspaceId, entityId }) =>
    api.get(`v1/${workspaceId}/subscriptions/${entityId}/subscribers/`),

  manageSubscribers: ({
    workspaceId,
    entityId,
    entityType,
    added,
    deleted,
    created
  }) =>
    api.post(`v1/${workspaceId}/subscriptions/manage-subscribers/`, {
      entity_id: entityId,
      entity_type: entityType,
      add_subscribers: added,
      remove_subscribers: deleted,
      created
    }),

  fetchChatFilesInfo: ({ workspaceId, entityIds }) =>
    api.post(`v1/${workspaceId}/subscriptions/chat-files-info/`, {
      entity_ids: entityIds
    })
});
