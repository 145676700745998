import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import GoogleLogin from 'components/common/google-login';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { getUser, addGoogleAccount, updateUser } from 'store/user';

import { useFileView } from 'hooks/common/use-file-upload/use-file-view';
import {
  getIsEditableFile,
  getIsZip
} from 'hooks/common/use-file-upload/types';

import FolderIcon from './folter-icon';
import GoogleAccNotConnected from './google-acc-not-connected';

import styles from './use-google-account.module.scss';

const { Title } = Typography;

const modalProps = {
  width: 430,
  icon: null,
  okText: null,
  maskClosable: true,
  okButtonProps: {
    style: {
      display: 'none'
    }
  }
};

const styleModal = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  padding: 30
};

const styleCloseBtn = {
  width: 50,
  height: 50,
  position: 'absolute',
  padding: 0,
  top: 0,
  right: 0
};

export const useGoogleAccount = () => {
  const { t } = useTranslation(['ScreenErrors', 'Common']);

  const dispatch = useDispatch();
  const { view, prepare, closePrepared } = useFileView();

  const user = useSelector(getUser);

  const onAuth = async ({ token }) => {
    await dispatch(addGoogleAccount({ token }));
    await dispatch(updateUser({ user: { id: user.id } }));
    Modal.destroyAll();
  };

  const onClickDownload = async downloadFile => {
    await downloadFile();
    Modal.destroyAll();
  };

  const closeLoadingModal = time => {
    clearTimeout(time);
    Modal.destroyAll();
  };

  // file, downloadFile, getUrl
  const check = async (file, downloadFile = () => {}, callback = () => {}) => {
    const { mimeType, type, url } = file;

    // проверяем zip формат, потому что у него mimeType такой же, как и у docx, и он хочет в превью
    if (getIsZip(file)) {
      return downloadFile();
    }

    if (!user.googleEmail && getIsEditableFile({ mimeType, type })) {
      return Modal.info({
        ...modalProps,
        content: (
          <div style={styleModal}>
            <Button
              type="link"
              style={styleCloseBtn}
              onClick={Modal.destroyAll}
            >
              <Icon type="close" color="black-55" />
            </Button>

            <Icon
              component={GoogleAccNotConnected}
              style={{
                marginBottom: 30,
                marginTop: 20,
                justifyContent: 'center'
              }}
            />

            <Typography.Title
              level={1}
              weight="bold"
              style={{ meginBottom: 20 }}
              color="black"
            >
              {t('ConnectYourGoogleAccountHeading', { ns: 'ScreenErrors' })}
            </Typography.Title>

            <Typography.Text style={{ marginBottom: 20 }} color="black-45">
              {t('GoogleNotConnectedFilesDesc', { ns: 'ScreenErrors' })}
            </Typography.Text>

            <GoogleLogin
              withIcon={false}
              type="primary"
              onAuth={onAuth}
              className={styles.googleBtn}
            />

            <Button type="link" onClick={() => onClickDownload(downloadFile)}>
              {t('DownloadFileBtn', { ns: 'ScreenErrors' })}
            </Button>
          </div>
        )
      });
    }

    const timeCallback = setTimeout(() => {
      Modal.info({
        ...modalProps,
        content: (
          <div style={styleModal}>
            <Button
              type="link"
              style={styleCloseBtn}
              onClick={Modal.destroyAll}
            >
              <Icon type="close" color="black-55" />
            </Button>

            <Icon component={FolderIcon} style={{ margin: '30px auto' }} />

            <Title style={{ fontSize: 25, marginBottom: 15 }}>
              {t('FileIsLoading', { ns: 'Common' })}
            </Title>

            <span style={{ fontSize: 14, opacity: 0.7, marginBottom: 15 }}>
              {t('FileIsLoadingWait', { ns: 'Common' })}
            </span>
          </div>
        )
      });
    }, 10);

    try {
      if (getIsEditableFile(file) && !file.channelUuid) {
        prepare();
      }

      let resFile = file;

      if (!url) {
        resFile = await callback();
      }

      return view(resFile);
    } catch {
      closePrepared();
    } finally {
      closeLoadingModal(timeCallback);
    }
  };

  return {
    check
  };
};

export default useGoogleAccount;
