export const updateAttachmentSubscription = ({
  attachments,
  setAttachments,
  data,
  isSubscribed
}) => {
  const updatedAttachments = attachments.map(attachment =>
    attachment.fileId === data.entityId
      ? { ...attachment, isSubscribed }
      : attachment
  );
  setAttachments(updatedAttachments);
};
