import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FilterMenu as Menu } from 'components/common/filter-menu';
import Icon from 'components/common/icon';

import {
  setFilterMySubscriptions,
  getFilterAttachments
} from 'store/attachments';

import styles from './filters.module.scss';

const FILTER_ALL = 'filterAll';
const MY_SUBSCRIPTIONS = 'mySubscriptions';

export const MenuFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('MyDrive');

  const { isSubscribed } = useSelector(getFilterAttachments);

  const setMySubscriptions = value => dispatch(setFilterMySubscriptions(value));

  const getSelectedKeysOnMenu = () => {
    const keys = [];

    if (isSubscribed) {
      keys.push(MY_SUBSCRIPTIONS);
      return keys;
    }

    keys.push(FILTER_ALL);

    return keys;
  };

  const selectedKeys = getSelectedKeysOnMenu();

  const resetFilter = () => {
    setMySubscriptions(false);
  };

  useEffect(() => {
    resetFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = [
    {
      label: t('AllDriveFilter'),
      key: FILTER_ALL,
      onClick: () => resetFilter(),
      visible: true
    },
    {
      label: (
        <>
          <Icon type="subscribe-filled" color="brand" size={20} />

          {t('MySubscriptionsDriveFilter')}
        </>
      ),
      key: MY_SUBSCRIPTIONS,
      onClick: () => {
        if (isSubscribed) {
          return null;
        }

        return setMySubscriptions(true);
      },
      visible: true
    }
  ];

  const filteredMenu = menu.filter(item => item.visible);

  return (
    <Menu
      menu={filteredMenu}
      selectedKeys={selectedKeys}
      className={styles.filterMenu}
    />
  );
};

export default MenuFilter;
