import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { TYPE_FILE } from 'constants/index';

import SubscribersDrawer from 'components/common/subscriptions/subscribers-drawer';

import { changeManageSubscribers } from 'store/subscriptions';

const AttachmentsSubscribersDrawer = ({ file, visible, onClose }) => {
  const dispatch = useDispatch();

  const onSubmit = ({ added, deleted }) => {
    dispatch(
      changeManageSubscribers({
        entityId: file.fileId,
        entityType: TYPE_FILE,
        added,
        deleted
      })
    );
  };

  return (
    <SubscribersDrawer
      data={file}
      visible={visible}
      onClose={onClose}
      submit={onSubmit}
    />
  );
};

AttachmentsSubscribersDrawer.propTypes = {
  file: PropTypes.shape({
    canManageSubscribers: PropTypes.bool,
    canSubscribe: PropTypes.bool,
    createdAt: PropTypes.string,
    creator: PropTypes.shape({
      id: PropTypes.number,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      avatarFile: PropTypes.object
    }),
    entities: PropTypes.arrayOf(PropTypes.object),
    fileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fileSize: PropTypes.number,
    highlight: PropTypes.any,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isSubscribed: PropTypes.bool,
    isSystem: PropTypes.bool,
    isTrash: PropTypes.bool,
    mimeType: PropTypes.string,
    permissions: PropTypes.object,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    workspace: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AttachmentsSubscribersDrawer;
