import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'components/common/typography';
import EmployeeAvatar from 'components/common/avatar/employee';

import { getFullName } from 'utils/get-fio';

import styles from './subscribers-drawer.module.scss';

const Subscriber = ({ info }) => (
  <div className={styles.info}>
    <div className={styles.nameWrap}>
      <EmployeeAvatar
        isLink={false}
        tooltip={{ isHide: true }}
        employee={info}
      />

      <Typography.Text ellipsis>{getFullName(info)}</Typography.Text>
    </div>

    {info.position && (
      <Typography.Text ellipsis size="small">
        {info.position}
      </Typography.Text>
    )}
  </div>
);

Subscriber.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    avatarFile: PropTypes.object,
    position: PropTypes.string
  }).isRequired
};

export default Subscriber;
