import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';

import { fetchSubscribers } from 'store/subscriptions';

import mapValue from 'utils/map-value';
import { showNoticeMessage } from 'services/notice';

import Form from './form';

export const SubscribersDrawer = ({ visible, submit, onClose, data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('FileSubscribers');

  const [afterVisible, setAfterVisible] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [defaultSubscribers, setDefaultSubscribers] = useState(null);
  const [deletedSubscribers, setDeletedSubscribers] = useState([]);

  const onFetch = async () => {
    try {
      setIsFetchLoading(true);

      const subscribers = await dispatch(
        fetchSubscribers({ entityId: data.fileId })
      );

      setDefaultSubscribers(subscribers);
    } finally {
      setIsFetchLoading(false);
    }
  };

  const onDelete = subscriber => {
    setDefaultSubscribers(prev => prev.filter(e => e.id !== subscriber.id));
    setDeletedSubscribers(prev => [...prev, subscriber]);
  };

  const onSubmit = async ({ subscribers }) => {
    try {
      setIsSubmitLoading(true);

      await submit({
        added: (subscribers || []).map(mapValue),
        deleted: deletedSubscribers.map(e => e.id)
      });

      onClose();
      showNoticeMessage();
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (afterVisible) {
      onFetch();
    } else {
      setDeletedSubscribers([]);
      setDefaultSubscribers(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterVisible]);

  return (
    <Drawer
      title={
        <>
          <Drawer.Back onClick={onClose} />
          <Drawer.Title>{t('FileSubscribersHeading')}</Drawer.Title>
        </>
      }
      visible={visible}
      closable={false}
      width={480}
      afterVisibleChange={setAfterVisible}
      onClose={onClose}
    >
      {isFetchLoading || !defaultSubscribers ? (
        <Spin />
      ) : (
        <Form
          isLoading={isSubmitLoading}
          onSubmit={onSubmit}
          onDelete={onDelete}
          subscribers={defaultSubscribers}
          data={data}
          deletedSubscribers={deletedSubscribers}
        />
      )}
    </Drawer>
  );
};

SubscribersDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    canManageSubscribers: PropTypes.bool,
    canSubscribe: PropTypes.bool,
    createdAt: PropTypes.string,
    creator: PropTypes.shape({
      id: PropTypes.number,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      avatarFile: PropTypes.object
    }),
    entities: PropTypes.arrayOf(PropTypes.object),
    fileId: PropTypes.string,
    fileSize: PropTypes.number,
    highlight: PropTypes.any,
    id: PropTypes.number,
    isSubscribed: PropTypes.bool,
    isSystem: PropTypes.bool,
    isTrash: PropTypes.bool,
    mimeType: PropTypes.string,
    permissions: PropTypes.object,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    workspace: PropTypes.number
  })
};

SubscribersDrawer.defaultProps = {
  data: {}
};

export default SubscribersDrawer;
