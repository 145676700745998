import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { getUserEmployee } from 'store/workspace';

import Subscriber from './subscriber';

import styles from './subscribers-drawer.module.scss';

const SubscribersList = ({ subscribers, onDelete, data }) => {
  const { t } = useTranslation('FileSubscribers');

  const employee = useSelector(getUserEmployee);

  const allowDelete = subscriber =>
    data.canManageSubscribers || employee.id === subscriber.id;

  return (
    <div className={styles.content}>
      <Typography.Paragraph>
        {t('EmployersMonitoringUpdatesText')}
      </Typography.Paragraph>

      <div className={styles.list}>
        {subscribers.map(subscriber => (
          <div key={subscriber.id} className={styles.listItem}>
            <Subscriber info={subscriber} />

            {allowDelete(subscriber) && (
              <Button
                icon="close"
                type="text"
                mood="none"
                onClick={() => onDelete(subscriber)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

SubscribersList.propTypes = {
  subscribers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      avatarFile: PropTypes.object,
      position: PropTypes.string
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  data: PropTypes.shape({
    canManageSubscribers: PropTypes.bool,
    canSubscribe: PropTypes.bool,
    createdAt: PropTypes.string,
    creator: PropTypes.shape({
      id: PropTypes.number,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      avatarFile: PropTypes.object
    }),
    entities: PropTypes.arrayOf(PropTypes.object),
    fileId: PropTypes.string,
    fileSize: PropTypes.number,
    highlight: PropTypes.any,
    id: PropTypes.number,
    isSubscribed: PropTypes.bool,
    isSystem: PropTypes.bool,
    isTrash: PropTypes.bool,
    mimeType: PropTypes.string,
    permissions: PropTypes.object,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    workspace: PropTypes.number
  }).isRequired
};

export default SubscribersList;
