import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_ORDER_STATUS,
  ONLINE_CHAT,
  TYPE_ORDER,
  DIALOG_TYPE_MESSAGE
} from 'constants/index';

import Drawer from 'components/common/drawer';
import { useViewFile } from 'components/attachments-view/hooks';

import { fetchAttachments } from 'store/attachments';
import { fetchOneContactLocal } from 'store/contacts';
import { setSendingMessageData } from 'store/operator';
import { fetchOne } from 'store/assets';
import { changeManageSubscribers } from 'store/subscriptions';
import { fetchTaskLocal } from 'store/tasks';

import Tabs from './tabs';

export const getFile = async ({
  relationId,
  relationType,
  onlineChat,
  contactId,
  fileList,
  dispatch
}) => {
  let file;
  let chat = onlineChat;

  if (relationType === TYPE_ASSET) {
    if (!chat) {
      const asset = await dispatch(fetchOne({ id: relationId }));
      chat = asset.chats.find(c => c.channelKind === ONLINE_CHAT);
    }

    dispatch(
      setSendingMessageData({
        entityType: relationType,
        entityId: relationId,
        onlineChat: chat,
        fileList,
        kind: DIALOG_TYPE_MESSAGE
      })
    );
  }

  if (relationType === TYPE_TASK) {
    if (!chat) {
      const task = await dispatch(fetchTaskLocal({ id: relationId }));
      chat = task.chats.find(c => c.channelKind === ONLINE_CHAT);
    }

    dispatch(
      setSendingMessageData({
        entityType: relationType,
        entityId: relationId,
        onlineChat: chat,
        fileList,
        kind: DIALOG_TYPE_MESSAGE
      })
    );
  }

  if (relationType === TYPE_CONTACT) {
    dispatch(
      setSendingMessageData({
        entityType: TYPE_CONTACT,
        entityId: contactId,
        onlineChat: chat,
        fileList
      })
    );
  }

  if (relationType === TYPE_ORDER_STATUS || relationType === TYPE_ORDER) {
    const contact = await dispatch(fetchOneContactLocal({ id: contactId }));
    const contactOnlineChat = contact.chats.find(
      c => c.channelKind === ONLINE_CHAT
    );

    dispatch(
      setSendingMessageData({
        entityType: TYPE_CONTACT,
        entityId: contactId,
        onlineChat: contactOnlineChat,
        fileList
      })
    );
  }

  return file;
};

export const AddFileDrawer = ({ visible, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('AddFile');

  const [isLoading, setIsLoading] = useState(false);

  const { viewFile } = useViewFile();

  const onSubmit = async ({
    fileList,
    relation,
    subscribersForAttachments
  }) => {
    try {
      setIsLoading(true);

      const { relationId, relationType, onlineChat, contactId } = Array.isArray(
        relation
      )
        ? relation[0]
        : relation;

      const file = await getFile({
        relationId,
        relationType,
        fileList,
        onlineChat,
        contactId,
        dispatch
      });

      const subscriberPromises = subscribersForAttachments.map(sub =>
        dispatch(changeManageSubscribers({ ...sub, created: true }))
      );

      if (subscriberPromises.length > 0) {
        await Promise.all(subscriberPromises);
      }

      onClose();

      dispatch(fetchAttachments({ isTrash: false }));

      if (file) {
        viewFile(file);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={<Drawer.Title>{t('AddFileHeading')}</Drawer.Title>}
      destroyOnClose
      visible={visible}
      bodyStyle={{
        padding: 0
      }}
      onClose={onClose}
    >
      <Tabs isLoading={isLoading} onSubmit={onSubmit} />
    </Drawer>
  );
};

AddFileDrawer.defaultProps = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AddFileDrawer;
