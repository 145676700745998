import api from 'api';
import { createAction } from 'redux-actions';

import createActionThunk from 'store/actions-thunk';
import { getWorkspaceId } from 'store/workspace';

export const setSendingMessageData = createAction(
  'operator/set-sending-message-data'
);

export const setIsNeedToReconnect = createAction(
  'operator/set-is-need-to-reconnect'
);

export const setEntityChatIsMessagesLoading = createAction(
  'operator/set-is-messages-loading'
);

export const setEntityChatHasNewMessages = createAction(
  'operator/set-entity-chat-has-new-messages'
);

export const setEntityChatRoomUuids = createAction(
  'operator/set-entity-chat-uuids'
);

export const setOperatorIsReady = createAction('operator/set-is-ready');

export const clearEntityChats = createAction('operator/clear-entity-chats');

export const changeNotificationChatDisplay = createAction(
  'operator/change-notification-chat-display'
);

export const setChatEntity = createAction('operator/set-chat-entity');

const operatorChats = ({ params, data, cancelable }) => {
  const accessToken = localStorage.getItem('token');

  const fetch = cancelable
    ? api.messenger.operatorChatsWithCancel
    : api.messenger.operatorChats;

  return fetch({
    data,
    params: {
      accessToken,
      ...params
    }
  }).then(({ data: result }) => result.data);
};

export const joinChatRooms = createActionThunk(
  'operator/join-chat-rooms',
  ({ params, data, dispatch }) => {
    dispatch(setEntityChatRoomUuids(data));

    return operatorChats({ params, data });
  }
);

export const getChatMessages = createActionThunk(
  'operator/get-chat-messages',
  ({ params, data, dispatch, cancelable }) => {
    dispatch(setEntityChatRoomUuids(data));

    return operatorChats({ params, data, cancelable }).then(([chat]) => chat);
  }
);

export const fetchUnreadedNotificationsCount = createActionThunk(
  'operator/fetch-unreaded-notifications-count',
  ({ roomUuids }) => {
    const accessToken = localStorage.getItem('token');

    return api.messenger
      .fetchUnreadedNotificationsCount({
        roomUuids,
        params: {
          accessToken
        }
      })
      .then(({ data }) => data.data);
  }
);

export const chatMessageNew = createAction('operator/chat-message-new');

export const chatMessageNewOk = createAction('operator/chat-message-new-ok');

export const chatMessageRead = createAction('operator/chat-message-read');

export const messageReactionSet = createAction('operator/message-reaction-set');

export const messageReactionUnset = createAction(
  'operator/message-reaction-unset'
);

export const setHighlightingMessageReaction = createAction(
  'operator/set-highlighting-message-reaction'
);

export const sendDraftMessage = createAction('operator/send-draft-message');

export const deleteDraftMessage = createAction('operator/delete-draft-message');

export const notificationMessageRead = createAction(
  'operator/notification-message-read'
);

export const allNotificationMessagesRead = createAction(
  'operator/all-notification-messages-read'
);

export const failedRemovedMessage = createAction(
  'operator/failed-removed-message'
);

export const setIsNotSendedMessage = createAction(
  'operator/set-is-not-sended-message'
);

export const resendExistingMessage = createAction(
  'operator/resend-existing-message'
);

export const employeeTyping = createAction('operator/employee-typing');

export const fetchDiscussionStatuses = createActionThunk(
  'operator/fetch-discussion-statuses',
  ({ getState, roomUuids }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.discussions
      .fetchDiscussionStatuses({
        workspaceId,
        roomUuids
      })
      .then(({ data }) => data);
  }
);

export const setNotificationsFilterSender = createAction(
  'operator/set-filter-sender'
);

export const setNotificationsFilterCreatedDateRange = createAction(
  'operator/set-filter-created-date-range'
);

export const setNotificationsFilterEntityType = createAction(
  'operator/set-filter-entity-type'
);

export const clearFilter = createAction('operator/clear-filter');

export const setCollapsedWidgetPanel = createAction(
  'operator/set-collapsed-widget-panel'
);

export const setCollapsedNotificationsFilterPanel = createAction(
  'operator/set-collapsed-notifications-filter-panel'
);

export const setCollapsedNotificationsInBellFilterPanel = createAction(
  'operator/set-collapsed-notifications-in-bell-filter-panel'
);
