export const REFRESH_AUTH_TOKEN_DELAY = 60 * 60;

export default REFRESH_AUTH_TOKEN_DELAY;

export * from './positions';
export * from './roles';
export * from './pagination';
export * from './router';
export * from './calendar';
export * from './status';
export * from './socket';
export * from './contacts';
export * from './units';
export * from './tasks';
export * from './rates';
export * from './customers';
export * from './videos';
export * from './notifications';
export * from './dialogues';
export * from './channels';
export * from './amplitude';
export * from './drawers';
export * from './orders';
export * from './tests';
export * from './reports';
export * from './env';
export * from './modals';
export * from './gpt';
export * from './discussion';
export * from './attachments';
