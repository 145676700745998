import React from 'react';

export const SubscribeFilledIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 21.5V2H21V21.5L12 14.5L2.5 21.5Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M12.4977 15.3734L12.0002 15.0143L11.5027 15.3734L2.59414 21.8037V1.85H21.4062V21.8037L12.4977 15.3734Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.7"
    />
  </svg>
);

export default SubscribeFilledIcon;
