import React from 'react';

export const NoSubscribersIcon = ({ style = { width: 105, height: 105 } }) => (
  <svg
    style={style}
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_101594_295447)">
      <path
        d="M71.1216 19.5819C79.4806 25.6852 105.125 48.9163 102.717 65.3226C100.452 80.7689 87.2779 78.9376 76.359 85.3281C58.3447 95.8739 28.0587 92.4819 10.5094 73.6134C-7.05178 54.761 2.93929 24.9844 19.0817 16.0187C35.2262 7.06703 54.9023 7.72684 71.1275 19.5738L71.1216 19.5819Z"
        fill="#EBF8F8"
      />
      <path
        d="M90.4396 67.6989C90.4396 72.8089 86.8396 76.9489 82.3896 76.9489H22.1096C17.6596 76.9489 14.0596 72.8089 14.0596 67.6989V21.6989C14.0596 16.5889 17.6596 12.4489 22.1096 12.4489H82.3896C86.8396 12.4489 90.4396 16.5889 90.4396 21.6989V67.6989Z"
        fill="#E2E2E2"
      />
      <path
        d="M90.4396 22.0689H14.0596V20.4989C14.0596 16.0489 17.6596 12.4489 22.1096 12.4489H82.3896C86.8396 12.4489 90.4396 16.0489 90.4396 20.4989V22.0689Z"
        fill="#16969C"
      />
      <path
        d="M24.1096 19.5788C25.3578 19.5788 26.3696 18.567 26.3696 17.3188C26.3696 16.0707 25.3578 15.0588 24.1096 15.0588C22.8614 15.0588 21.8496 16.0707 21.8496 17.3188C21.8496 18.567 22.8614 19.5788 24.1096 19.5788Z"
        fill="white"
      />
      <path
        d="M32.7493 19.5788C33.9974 19.5788 35.0093 18.567 35.0093 17.3188C35.0093 16.0707 33.9974 15.0588 32.7493 15.0588C31.5011 15.0588 30.4893 16.0707 30.4893 17.3188C30.4893 18.567 31.5011 19.5788 32.7493 19.5788Z"
        fill="white"
      />
      <path
        d="M41.3997 19.5788C42.6478 19.5788 43.6596 18.567 43.6596 17.3188C43.6596 16.0707 42.6478 15.0588 41.3997 15.0588C40.1515 15.0588 39.1396 16.0707 39.1396 17.3188C39.1396 18.567 40.1515 19.5788 41.3997 19.5788Z"
        fill="white"
      />
      <path
        d="M19.2695 22.0688H85.2095V66.1388C85.2095 68.8988 82.9695 71.1388 80.2095 71.1388H24.2695C21.5095 71.1388 19.2695 68.8988 19.2695 66.1388V22.0688Z"
        fill="#D7F1F2"
      />
      <path
        d="M62.287 33.7712L14.1186 28.5037C11.4439 28.2086 9.02361 30.1576 8.72855 32.8323L7.46662 44.3906C7.17156 47.0653 9.12699 49.4977 11.7953 49.7806L59.9636 55.0482C62.6384 55.3432 65.0586 53.3942 65.3537 50.7195L66.6156 39.1613C66.9107 36.4866 64.9617 34.0663 62.287 33.7712Z"
        fill="white"
      />
      <path
        d="M44.2546 45.675L31.8119 44.3135C31.3189 44.2634 30.9583 43.8168 31.0177 43.3267C31.0771 42.8366 31.5145 42.4731 32.0045 42.5325L44.4472 43.894C44.9402 43.9441 45.3008 44.3907 45.2414 44.8808C45.182 45.3709 44.7446 45.7344 44.2546 45.675Z"
        fill="#2D9EA3"
      />
      <path
        d="M56.876 42.6586L32.2835 39.9751C31.7906 39.9249 31.4299 39.4783 31.4893 38.9882C31.5488 38.4982 31.9861 38.1346 32.4762 38.1941L57.0686 40.8776C57.5616 40.9278 57.9222 41.3744 57.8628 41.8644C57.8034 42.3545 57.3661 42.718 56.876 42.6586Z"
        fill="#2D9EA3"
      />
      <path
        d="M53.8834 46.7293L49.2798 46.2265C48.7868 46.1764 48.4262 45.7298 48.4856 45.2397C48.545 44.7496 48.9824 44.3861 49.4724 44.4455L54.076 44.9483C54.569 44.9984 54.9296 45.445 54.8702 45.9351C54.8108 46.4252 54.3734 46.7887 53.8834 46.7293Z"
        fill="#2D9EA3"
      />
      <path
        d="M24.7763 40.4488C24.416 43.7643 21.4313 46.1596 18.1157 45.7994C14.8002 45.4392 12.4048 42.4544 12.7651 39.1388C13.1253 35.8233 16.1101 33.428 19.4256 33.7882C22.7412 34.1485 25.1365 37.1332 24.7763 40.4488Z"
        fill="url(#paint0_linear_101594_295447)"
      />
      <path
        d="M17.847 42.6767C17.6098 42.6545 17.3919 42.5365 17.237 42.3464L15.6893 40.4057C15.3822 40.0162 15.4431 39.4554 15.8326 39.1483C16.222 38.8412 16.7828 38.9021 17.0899 39.2915L18.049 40.502L20.6101 38.2609C20.9839 37.9388 21.5532 37.9718 21.8753 38.3455C22.1974 38.7192 22.1644 39.2886 21.7907 39.6107L18.5215 42.4675C18.3407 42.6254 18.0906 42.711 17.8534 42.6888L17.8348 42.6831L17.847 42.6767Z"
        fill="white"
      />
      <path
        d="M68.5732 26.3306L65.8903 35.1769C65.4681 36.5691 64.0047 37.3317 62.6439 36.9225L58.3591 35.6339L54.6697 37.2779L52.5108 33.8752L48.2549 32.5953C46.8653 32.1774 46.0994 30.7444 46.5216 29.3522L49.2045 20.506C49.6267 19.1138 51.0901 18.3511 52.4508 18.7604L66.8398 23.0875C68.2295 23.5054 68.9866 24.9674 68.5732 26.3306Z"
        fill="#32C9C9"
      />
      <path
        d="M54.9249 23.2153C54.9513 23.1275 55.049 23.0793 55.1431 23.1077L61.9613 25.158C62.0554 25.1863 62.1103 25.2804 62.0839 25.3682L60.0267 32.2091C59.9877 32.3388 59.8076 32.3665 59.7177 32.2567L57.0782 29.0344L53.0991 30.2664C52.9636 30.3083 52.8287 30.1859 52.8676 30.0562L54.9249 23.2153Z"
        fill="#EBF8F8"
      />
      <path
        d="M37.2342 59.4125L39.7719 71.5147C40.1713 73.4194 38.9446 75.2545 37.0853 75.6489L31.2304 76.891L27.9587 81.1907L23.2392 78.5862L17.4238 79.8198C15.5249 80.2227 13.7054 79.0337 13.3061 77.1291L10.7683 65.0268C10.3689 63.1222 11.5956 61.2871 13.4549 60.8927L33.1164 56.7217C35.0153 56.3189 36.8431 57.5475 37.2342 59.4125Z"
        fill="#32C9C9"
      />
      <path
        d="M19.2199 64.5293C19.1945 64.4094 19.2781 64.29 19.4067 64.2627L28.7232 62.2864C28.8519 62.2591 28.9768 62.3342 29.0022 62.4541L30.9852 71.8017C31.0227 71.9788 30.8293 72.1271 30.6533 72.0561L25.4858 69.9722L21.6094 73.9747C21.4774 74.111 21.2404 74.054 21.2029 73.8769L19.2199 64.5293Z"
        fill="#EBF8F8"
      />
      <path
        d="M99.1323 60.5197L93.1383 72.7266C92.195 74.6477 89.8895 75.4077 88.011 74.4907L82.0958 71.6033L76.3493 73.1148L74.0223 67.6624L68.147 64.7945C66.2286 63.858 65.4566 61.5958 66.3999 59.6747L72.3939 47.4678C73.3372 45.5467 75.6427 44.7868 77.5212 45.7037L97.3852 55.3999C99.3036 56.3364 100.056 58.6386 99.1323 60.5197Z"
        fill="#32C9C9"
      />
      <path
        d="M80.056 52.7586C80.1151 52.6374 80.2684 52.5906 80.3984 52.654L89.8108 57.2486C89.9408 57.312 89.9982 57.4616 89.939 57.5828L85.3292 67.0267C85.2418 67.2056 84.9738 67.2032 84.8693 67.0225L81.8016 61.7179L75.7323 62.5624C75.5256 62.5912 75.3588 62.3814 75.4461 62.2025L80.056 52.7586Z"
        fill="#EBF8F8"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_101594_295447"
        x1="12.7525"
        y1="39.125"
        x2="24.7787"
        y2="40.4396"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <clipPath id="clip0_101594_295447">
        <rect width="105" height="105" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default NoSubscribersIcon;
