import React from 'react';
import AntdIcon from 'antd/lib/icon';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  ArrowIcon,
  LogoutIcon,
  PlusCircleIcon,
  PlusIcon,
  CloseIcon,
  CheckIcon,
  TimerIcon,
  CopyIcon,
  SnippetsIcon,
  DiffIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  AuditIcon,
  AtIcon,
  BigTeamIcon,
  StoryPointsIcon,
  SafetyIcon,
  PushpinIcon,
  PointerIcon,
  ListIcon,
  CanbanIcon,
  FileIcon,
  MoneyCollectIcon,
  VerifiedIcon,
  PresentationIcon,
  CommentIcon,
  SprintIcon,
  PhoneAndMessageIcon,
  OpenInNewWindowIcon,
  HistogramIcon,
  AppstoreAddIcon,
  BubbleChartIcon,
  FieldBinaryIcon,
  MapIcon,
  SankeyChartIcon,
  SunburstIcon,
  HistoryUndoIcon,
  HistoryRedoIcon,
  NodeIndexIcon,
  ReplyIcon,
  ReplyToAllIcon,
  SwitcherPlusIcon,
  AnimatedClockCircleIcon,
  SubtractIcon,
  VisaIcon,
  MastercardIcon,
  BelcartIcon,
  GptWidgetIcon,
  SingleCheckboxIcon,
  DoubleCheckboxIcon,
  ProjectIcon,
  ChannelIcon,
  TeamIcon,
  PenIcon,
  AiAssistantIcon,
  SelectTagIcon,
  SubscribeIcon,
  SubscribeFilledIcon,
  SubscribersIcon
} from './icons';

const ICON = {
  'sort-ascending': SortAscendingIcon,
  'sort-descending': SortDescendingIcon,
  'plus-circle': PlusCircleIcon,
  'big-team': BigTeamIcon,
  'story-points': StoryPointsIcon,
  arrow: ArrowIcon,
  logout: LogoutIcon,
  plus: PlusIcon,
  close: CloseIcon,
  check: CheckIcon,
  timer: TimerIcon,
  copy: CopyIcon,
  snippets: SnippetsIcon,
  diff: DiffIcon,
  audit: AuditIcon,
  at: AtIcon,
  safety: SafetyIcon,
  pushpin: PushpinIcon,
  pointer: PointerIcon,
  list: ListIcon,
  canban: CanbanIcon,
  attachment: FileIcon,
  'monney-collect': MoneyCollectIcon,
  verified: VerifiedIcon,
  presentation: PresentationIcon,
  comment: CommentIcon,
  sprint: SprintIcon,
  'phone-and-message': PhoneAndMessageIcon,
  'open-in-new-widnow': OpenInNewWindowIcon,
  histogram: HistogramIcon,
  'appstore-add': AppstoreAddIcon,
  'bubble-chart': BubbleChartIcon,
  'field-binary': FieldBinaryIcon,
  'choropleth-map': FieldBinaryIcon,
  map: MapIcon,
  'snakey-chart': SankeyChartIcon,
  sunburst: SunburstIcon,
  'history-redo': HistoryRedoIcon,
  'history-undo': HistoryUndoIcon,
  'node-index': NodeIndexIcon,
  reply: ReplyIcon,
  'reply-to-all': ReplyToAllIcon,
  'switcher-plus': SwitcherPlusIcon,
  'animated-clock-circle': AnimatedClockCircleIcon,
  subtract: SubtractIcon,
  visa: VisaIcon,
  mastercard: MastercardIcon,
  belcart: BelcartIcon,
  'gpt-widget': GptWidgetIcon,
  'single-checkbox': SingleCheckboxIcon,
  'double-checkbox': DoubleCheckboxIcon,
  project: ProjectIcon,
  channel: ChannelIcon,
  team: TeamIcon,
  pen: PenIcon,
  'ai-assistant': AiAssistantIcon,
  'select-tag': SelectTagIcon,
  subscribe: SubscribeIcon,
  'subscribe-filled': SubscribeFilledIcon,
  subscribers: SubscribersIcon
};

const ROTATE = {
  default: 'rotate(0deg)',
  left: 'rotate(90deg)',
  right: 'rotate(-90deg)',
  up: 'rotate(180deg)',
  down: 'rotate(-180deg)'
};

/**
 * **Icon**
 *
 * @param  props
 * @param  props.type { one of ICON object keys or antd icon type } null
 * @param  props.component { for multicolor and other custom icon component } null
 * @param  props.color { one of theme colors } inherit
 * @param  props.side { default | left | right | up | down } default
 * @param  props.size { icon size (fontSize px) } 24 (viewbox width/height)
 */

export const Icon = ({
  type,
  component,
  side,
  color,
  size,
  style,
  className,
  ...props
}) => (
  <AntdIcon
    {...props}
    component={ICON[type] || component}
    type={!ICON[type] && !component ? type : undefined}
    className={classnames(`color-${color}`, className)}
    style={{
      display: 'inline-flex',
      transform: ROTATE[side],
      transition: ' 0.2s ease-in-out',
      transformOrigin: 'center center',
      fontSize: size,
      ...style
    }}
  />
);

Icon.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.oneOf(Object.keys(ICON)),
    PropTypes.string
  ]),
  component: PropTypes.any,
  side: PropTypes.oneOf(Object.keys(ROTATE)),
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string
};

Icon.defaultProps = {
  type: null,
  component: null,
  side: 'default',
  color: 'inherit',
  size: 24,
  style: {},
  className: undefined
};

export default Icon;
