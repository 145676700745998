import React, { createContext, useRef, useState } from 'react';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS, $convertToMarkdownString } from '@lexical/markdown';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import PropTypes from 'prop-types';
import { LineBreakNode } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import useFilePaste from 'hooks/common/use-file-paste';

import { TopToolbarPlugin } from './plugins/top-toolbar';
import AutoLinkPlugin from './plugins/auto-link';
import { MENTION_MARKDOWN, MentionNode } from './nodes/mention-node';
import theme from './themes/theme';
import { updateEditorState } from './utils';
import MentionPlugin from './plugins/mention';

import styles from './editor.module.scss';

export const MarkdownEditorContext = createContext();

const editorConfig = {
  theme,
  onError(error) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    AutoLinkNode,
    LinkNode,
    MentionNode,
    CodeHighlightNode,
    CodeNode,
    LineBreakNode
  ]
};

export const Editor = ({
  value,
  placeholder,
  onChange,
  isHtml,
  isDisabled,
  showItems,
  defaultVisibleToolbar,
  bottomToolbar,
  editorInputClassName,
  editorContainerClassName,
  fileList,
  onChangeFileList,
  setMentionedEmployees,
  resizeInput,
  destination
}) => {
  const editorRef = useRef(null);
  const [showToolbar, setShowToolbar] = useState(defaultVisibleToolbar);

  const { t } = useTranslation('Common');

  const handleChange = (editorState, editor) => {
    editor.update(() => {
      const markdown = $convertToMarkdownString([
        MENTION_MARKDOWN,
        ...TRANSFORMERS
      ]);

      const rawHTML = $generateHtmlFromNodes(editor, null);

      onChange({
        ...value,
        description: isHtml ? rawHTML : markdown
      });
    });
  };

  useFilePaste({
    allowPaste: showItems && showItems.upload && !isDisabled,
    target: editorRef.current,
    fileList,
    onChange: onChangeFileList
  });

  return (
    <MarkdownEditorContext.Provider value={{ showToolbar, setShowToolbar }}>
      <LexicalComposer
        initialConfig={{
          ...editorConfig,
          editable: !isDisabled,
          editorState: editor => updateEditorState({ value, editor, isHtml })
        }}
      >
        <div
          className={classnames(
            styles.editorContainer,
            editorContainerClassName,
            { [styles.isDisabled]: isDisabled }
          )}
        >
          {showItems.topToolbar && showToolbar && (
            <TopToolbarPlugin isDisabled={isDisabled} />
          )}

          <div className={styles.editorInner} ref={editorRef}>
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  className={classnames(
                    styles.editorInput,
                    editorInputClassName,
                    {
                      [styles.resizeInput]: resizeInput
                    }
                  )}
                />
              }
              placeholder={
                !isDisabled && (
                  <div className={styles.editorPlaceholder}>
                    {placeholder || t('EnterMessage')}
                  </div>
                )
              }
              ErrorBoundary={LexicalErrorBoundary}
            />

            {bottomToolbar}

            {showItems.mention && (
              <MentionPlugin
                setMentionedEmployees={setMentionedEmployees}
                destination={destination}
              />
            )}

            <HistoryPlugin />

            <ListPlugin />

            <LinkPlugin />

            <AutoLinkPlugin />

            <OnChangePlugin onChange={handleChange} />

            <MarkdownShortcutPlugin
              transformers={[MENTION_MARKDOWN, ...TRANSFORMERS]}
            />

            <ClearEditorPlugin />
          </div>
        </div>
      </LexicalComposer>
    </MarkdownEditorContext.Provider>
  );
};

Editor.propTypes = {
  value: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isHtml: PropTypes.bool,
  showItems: PropTypes.shape({
    mention: PropTypes.bool,
    topToolbar: PropTypes.bool
  }),
  defaultVisibleToolbar: PropTypes.bool,
  editorInputClassName: PropTypes.string,
  editorContainerClassName: PropTypes.string,
  bottomToolbar: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool,
  resizeInput: PropTypes.bool
};

Editor.defaultProps = {
  value: {},
  onChange: () => {},
  isHtml: false,
  defaultVisibleToolbar: false,
  editorInputClassName: undefined,
  editorContainerClassName: undefined,
  showItems: {
    mention: true,
    topToolbar: true
  },
  isDisabled: false,
  resizeInput: false
};

export default Editor;
