import React from 'react';

export const SubscribersIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 7.05144C17 3.74169 14.363 1.05214 11.093 1.00071C7.76905 0.949279 5.00006 3.69934 5.00006 7.05144C5.00006 8.95137 5.87006 10.6456 7.22605 11.7559C6.26217 12.2065 5.38472 12.825 4.63406 13.5832C2.99007 15.2381 2.06007 17.4224 2.00008 19.7519C1.99927 19.7842 2.00489 19.8163 2.01659 19.8464C2.0283 19.8765 2.04585 19.9039 2.06821 19.927C2.09058 19.9501 2.11731 19.9685 2.14682 19.981C2.17634 19.9936 2.20805 20 2.24007 20H3.92007C4.04906 20 4.15706 19.8971 4.16006 19.767C4.21706 18.0123 4.92206 16.3696 6.16105 15.1231C7.45405 13.8192 9.17004 13.1022 11 13.1022C14.312 13.1022 17 10.3945 17 7.05144ZM13.715 9.7894C12.989 10.5215 12.026 10.9239 11 10.9239C9.97404 10.9239 9.01104 10.5215 8.28504 9.7894C7.92276 9.42595 7.63651 8.99287 7.44326 8.5158C7.25002 8.03874 7.15371 7.5274 7.16005 7.01211C7.16905 6.01979 7.56205 5.06075 8.24904 4.34979C8.96904 3.60555 9.93204 3.19107 10.961 3.17897C11.978 3.1699 12.965 3.56924 13.691 4.28626C14.435 5.02142 14.843 6.00466 14.843 7.05144C14.84 8.08611 14.441 9.05726 13.715 9.7894Z"
      fill="currentColor"
    />
    <path
      d="M17.9791 20.206L17.5 19.8477L17.0209 20.206L13.8 22.6145V14.8H21.2V22.6145L17.9791 20.206Z"
      stroke="currentColor"
      strokeWidth="1.6"
    />
  </svg>
);

export default SubscribersIcon;
